import React, { Fragment } from 'react'
import Loader from '@shared/loader'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import { OPENMAPFIELDS } from '@modules/open-street-map-field'
import Swal from 'sweetalert2'
import toastr from '@modules/toastr'
import { fullName } from '@modules/form-wizard/utils'
import Modal from 'react-responsive-modal'
import moment from 'moment'
import { ValidatorForm } from 'react-form-validator-core'
import DateTimeField from '@shared/form-fields/date'

export default class ProtectedTreePermit extends React.Component {
  resource = '/protected_tree_permits'
  form_key = 'protected_tree_permit'

  isMultiple = /multiple/.test(this.props.location.pathname)

  defaults = {
    trees_num: 1
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        multiple_trees: this.isMultiple
      },
    },
  }

  // Delete lines as needed
  // fee           = 99
  // free          = false
  // postpaid      = true
  pay_on_approval = true
  skip_location = true
  hide_buttons  = true

  state = { record: null, loading: false, date: new Date() }

  coordinates = null

  // Allow map component selected coordinates to translate to form latitude and longitude fields
  validate = async values => {
    const update = {}

    if(this.coordinates) {
      update.latitude = this.coordinates[0]
      update.longitude = this.coordinates[1]
    }

    return update
  }

  foremost = null

  custom_actions = [
    // Assign Field Officer as Director
    {
      text: 'Assign Field Officer',
      icon: 'user-tie',
      roles: [['deputy_director', 'director']],
      test: r => ['pending', 'processing'].includes(r.application_decision),
      fn: async record => {
        const officerList = {};
        const { data } = await $app.axios.get(`${this.resource}/show_field_officers`)
        const officerMap = data.records.map(x => {
          return { id: x.id, name: `${x.first_name} ${x.last_name}` }
        })
        this.setState({ all_dept_officers: officerMap })
        this.state.all_dept_officers.forEach(e => {
          officerList[`${e.id}`] = e.name;
        })

        const assignOfficer = await Swal.fire({
          icon: 'question',
          title: 'Assign record to a Field Officer',
          html: `
            Select which field officer you would wish to assign to this application.
          `,
          input: 'select',
          inputOptions: officerList,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (assignOfficer.isDismissed) return
        const officerID = assignOfficer.value

        await this.submitToNewLevel('approved', record, officerID)
      },
    },

    // Assign Cashier Officer as Director
    {
      text: 'Assign Cashier Officer',
      icon: 'user-tie',
      roles: [['deputy_director', 'director']],
      test: r => ['field officer viewed'].includes(r.application_decision),
      fn: async record => {
        const officerList = {};
        const { data } = await $app.axios.get(`${this.resource}/show_cashier_officers`)
        const officerMap = data.records.map(x => {
          return { id: x.id, name: `${x.first_name} ${x.last_name}` }
        })
        this.setState({ all_dept_officers: officerMap })
        this.state.all_dept_officers.forEach(e => {
          officerList[`${e.id}`] = e.name;
        })

        const assignOfficer = await Swal.fire({
          icon: 'question',
          title: 'Assign record to a Field Officer',
          html: `
            Select which field officer you would wish to assign to this application.
          `,
          input: 'select',
          inputOptions: officerList,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (assignOfficer.isDismissed) return
        const officerID = assignOfficer.value

        await this.submitToNewLevel('approved', record, officerID)
      },
    },

    // Recommend Inspection Date as Field Officer
    {
      text: 'Set Inspection Date',
      icon: 'chevron-right',
      roles: ['field_officer'],
      test: r => r.application_decision == 'director viewed',
      fn: async record => this.setState({ record })
    },

    // Recommend Payable Amount as Cashier
    {
      text: 'Recommend Amount',
      icon: 'question',
      roles: [['cashier_officer']],
      test: r => ['director reviewed'].includes(r.application_decision),
      fn: async record => {
        let recommendedAmount = null;

        // Set value of Recommended Amount
        const amountPrompt = await Swal.fire({
          icon: 'question',
          title: 'Amount to be payable?',
          html: `
            What is your recommended amount to be payable regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'number',
          inputValue: record?.recommended_amount,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (amountPrompt.isDismissed) return

        recommendedAmount = amountPrompt.value

        await this.submitToNewLevel('approved', record, null, recommendedAmount, null)
      },
    },
    // {
    //   text: 'Approve For Payment',
    //   icon: 'check-square',
    //   roles: ['director'],
    //   fn: async record => 
    //     Swal.fire({
    //       title:
    //         'Are you sure that you would like to approve this application for payment?',
    //       text: 'By clicking "Confirm", you are acknowleding that you have reviewed this application and are satisfied with its contents. Would you like to approve?',
    //       icon: 'question',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       confirmButtonText: 'Confirm',
    //     }).then(async result => {
    //       if (result.isConfirmed) {
    //         try {
    //           await $app.axios.put(this.resource + '/update_payment_amount', {
    //             [this.form_key]: {
    //               form_num: record.form_num,
    //             },
    //           })
    //           toastr.success('Success', 'Application successfully approved for payment')
    //         } catch (err) {
    //           console.error(err)
    //           toastr.error('Error', 'Unable to approve application')
    //         }
    //       }
    //     })
    // },
    {
      text: 'Approve For Payment',
      icon: 'check-square',
      roles: ['director'],
      test: r => ['cashier officer viewed'].includes(r.application_decision),
      fn: async record => {
        let licenseNumber
        let recommendedAmount = null;

        // Set value of Recommended Amount
        const amountPrompt = await Swal.fire({
          icon: 'question',
          title: 'Amount to be payable?',
          html: `
            What is your recommended amount to be payable regarding the applicant,
            <strong>${fullName(record.user, 'initial')}</strong>?
          `,
          input: 'number',
          inputValue: record?.recommended_amount,
          showCancelButton: true,
          confirmButtonText: 'Send',
        })
  
        if (amountPrompt.isDismissed) return

        recommendedAmount = amountPrompt.value

        const { value } = await Swal.fire({
          icon: 'info',
          input: 'text',
          text: 'Please set the permit licence #',
          title: 'Permit Licence Number',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          // inputValidator: val => {
          //   if (Number(val) == 'NaN') {
          //     return 'Please enter a valid amount.'
          //   }
          // }
        })
    
        licenseNumber = value;
    
        if (value) {
          const { isConfirmed } = await Swal.fire({
            icon: 'question',
            title: 'Confirm Licence Number',
            html: `
              ${fullName(record.user, false)}
              Are you sure you want the payable amount to be: <b>${recommendedAmount}</b>
              Are you sure you want the licence number to be: <b>${licenseNumber}</b>
            `,
            showCancelButton: true,
            confirmButtonText: 'Submit',
          })
    
          if (isConfirmed) {
            await this.submitToNewLevel('approved', record, null, recommendedAmount, licenseNumber)
          }
        }
      }
    },
    {
      text: 'Deny',
      icon: 'ban',
      roles: ['director'],
      test: r => !['approved', 'denied'].includes(r.application_decision),
      fn: record => {
        Swal.fire({
          icon: 'error',
          title: 'Deny Application',
          text: 'By clicking deny, you are confirming that you are denying this application. Are you sure that you want to proceed?',
          showCancelButton: true,
          confirmButtonText: 'Deny',
          confirmButtonColor: '#C82333',
        }).then(async result => {
          if (result.isConfirmed) {
            const { resource, form_key, props } = this
            const { history, location } = props
            const { axios } = $app

            try {
              await axios.put(resource + '/update_application', {
                [form_key]: {
                  form_num: record.form_num,
                  application_decision: 'denied',
                },
              })

              if (location?.state?.view) {
                const view = { ...location.state.view }
                view.application_decision = 'denied'

                history.replace(location.pathname, {
                  ...location.state,
                  view,
                })
              }

              Swal.fire('Denied!', 'The application has been denied', 'success')

            } catch (error) {
              console.error(error)
              Swal.fire(
                'Oops...',
                'There has been an error with the denial of the application',
                'error'
              )
            }
          }
        })
      },
    },
  ]

  closeModal = () => this.setState({ record: null })

  onChange = date => this.setState({ date })

  submitToNewLevel = async (value, record, officerID = null, recommendedAmount = null, licenseNumb = null) => {
    const { resource, form_key } = this
    const { axios } = $app

    try {
      await axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: value,
          field_officer_id: officerID,
          cashier_officer_id: officerID,
          recommended_amount: recommendedAmount,
          license_numb: licenseNumb
        },
      })

      Swal.fire(
        'Submitted!',
        'The application successfully updated.',
        'success'
      )
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops..',
        'Something went wrong with trying to update this application',
        'error'
      )
      return
    }
  }

  setAppt = async () => {
    const { props, state, resource, form_key } = this
    const { history, location } = props
    const { date, record } = state

    this.setState({ loading: true })

    try {
      await $app.axios.put(resource + '/update_application', {
        [form_key]: {
          form_num: record.form_num,
          application_decision: 'approved',
          inspection_date: new Date(date),
        },
      })

      if (location?.state?.view) {
        const view = { ...location.state.view }
        view.application_decision = 'field officer viewed'

        history.replace(location.pathname, {
          ...location.state,
          view,
        })
      }

      toastr.success('Success', 'Application successfully updated')
    } catch (error) {
      console.error(error)
      Swal.fire(
        'Oops...',
        'There has been an error with processing your application',
        'error'
      )
    } finally {
      this.setState({ record: null, loading: false })
    }
  }

  columns = props => [
    {
      name: 'Inspection Date',
      selector: row => row.inspection_date ? moment(row.inspection_date).format('MMMM Do YYYY, h:mm a') : 'N/A',
      sortable: true,
      searchable: r => r.inspection_date,
    },
    {
      name: 'Payment Set',
      selector: row => row.recommended_amount || 'N/A',
      sortable: true,
      searchable: r => r.recommended_amount,
    },
  ]

  fields = form => [
    {
      name: 'inspection_date',
      type: 'date',
      hide: true,
      view: {
        label: 'Inspection Date',
        value: v => (v ? moment(v).format('MMMM Do YYYY, h:mm a') : 'N/A')
      },
    },
    {
      name: 'recommended_amount',
      hide: true,
      view: {
        label: 'Recommended Amount',
        value: v => v ? v : 'N/A'
      },
    },

    {
      name: 'i_am_applicant',
      label: 'I am applying for myself',
      type: 'select:bool'
    },
    {
      name: 'f_name',
      label: 'Applicant first name',
      hide: form.i_am_applicant,
    },
    {
      name: 'm_name',
      label: 'Applicant middle name',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'l_name',
      label: 'Applicant last name',
      hide: form.i_am_applicant,
    },
    {
      name: 'address',
    },
    {
      name: 'po_box',
      label: 'P.O. Box'
    },
    {
      name: 'island',
      options: islands
    },
    {
      name: 'telephone',
      type: 'phone'
    },
    {
      name: 'fax',
      type: 'phone',
    },
    {
      name: 'location',
      label: 'Location or site of the tree'
    },

    ...OPENMAPFIELDS(this),

    {
      name: 'valid_permit',
      label: 'Is the site covered by a valid permit for conduct of excavation or landfil?',
      type: 'select:bool'
    },
    {
      name: 'is_covered',
      label: 'Please provide the permit number',
      type: 'integer',
      hide: form.valid_permit != true
    },
    {
      name: 'not_covered',
      label: 'What is the reason for the removal of the trees?',
      type: 'textarea',
      hint: 'Example: Description of Operation: Land clearing, construction, removal/transplant/destruction of a tree for construction purposes.',
      hide: form.valid_permit != false
    },
    {
      name: 'land_clearing',
      label: 'Please provide details on the land clearing',
      type: 'textarea'
    },
    {
      name: 'size',
      type: 'decimal',
      label: 'Provide the size of the area (in acres)',
      hint: 'Eg. 0.3 acres'
    },
    {
      name: 'tree_names',
      label: 'Common and Scientific name of protected tree(s)',
      rowLabel: 'Tree',
      max: 1,
      fields: [ {
                  name: 'common_name',
                  label: 'Name/Type'
                },
                {
                  name: 'scientific_name'
                },
                {
                  name: 'age',
                  is: 'integer',
                  //required: false
                },
                {
                  name: 'size',
                  hint: 'Include metric of measurement used',
                  //required: false
                },
                {
                  name: 'height',
                  hint: 'Include metric of measurement used',
                  //required: false
                },
                {
                  name: 'quantity',
                  label: 'Number of Trees',
                }
              ]
    },
    {
      name: 'trees_num',
      label: 'Number of protected trees under the Act',
      type: 'integer',
      disabled: true,
    },
   /* {
      name: 'tree_measurments',
      label: 'Age/size/height of the tree(s)',
      rowLabel: 'Age/size/height of Tree',
      max: 1,
      fields: [
        {
          name: 'tree_name'
        },
        {
          name: 'age',
          is: 'integer',
          required: false
        },
        {
          name: 'size',
          required: false
        },
        {
          name: 'height',
          required: false
        }
      ]
    },*/
    {
      name: 'equipment_type_list',
      label: 'Type of equipment used for harvesting protected tree(s)',
      columns: ['equipment'],
      format: v => v.equipment
    },
    {
      name: 'proposed_method',
      label: 'Proposed method of harvesting protected tree(s)',
      type: 'textarea'
    },
    {
      name: 'invasive_species_removal',
      label: 'Proposed plan for Invasive species removal',
      type: 'textarea'
    },
    {
      name: 'restorative_plan_upload',
      label: 'Planned restorative and reinstatement plans and future land use plans for the site',
      hint: 'Mitigation/Reinforestation/Landscape plan',
      key: 'image.1',
      type: 'file:pdf'
    },
    {
      name: 'invasive_species_upload',
      label: 'Invasive species removal plan',
      key: 'images.2',
      type: 'file:pdf'
    },
    {
      name: 'authorization_letter_upload',
      label: 'Authorization Letter',
      key: 'images.3',
      type: 'file:pdf'
    }
  ]

  render = () => {
    const { loading } = this.state
    // console.log($app.current_user.id)

    return (
      <Fragment>
        <Loader loading={loading} />
        <Wizard {...SERVICE_PROPS(this)} />
        <Modal
          open={!!this.state.record && !this.state.loading}
          onClose={this.closeModal}
          classNames={{ modal: 'w-full sm:w-128' }}
          center
        >
        <header className='modal-header'>
          <h4 className='mb-0'>Set Inspection Date</h4>
        </header>
        <ValidatorForm onSubmit={this.setAppt}>
          <article className='modal-body'>
            <label htmlFor='date' className='form-label'>
              Please set the applicant's inspection date
            </label>
            <DateTimeField
              name='date'
              dateFormat="MMMM d, yyyy h:mm aa"
              timeInputLabel="Time:"
              showTimeInput
              icon={null}
              minDate={new Date()}
              selected={this.state.date}
              value={this.state.date}
              onChange={this.onChange}
              validators={['required']}
              errorMessages={['Please enter a valid date']}
              className='form-control'
              required
            />
          </article>
          <footer className='modal-footer'>
            <button type='button' className='btn' onClick={this.closeModal}>
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              Submit
            </button>
          </footer>
        </ValidatorForm>
      </Modal>
      </Fragment>
    )
  }
}
